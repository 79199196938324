import { graphql, commitMutation } from 'react-relay';

const mutation = graphql`
  mutation requestEarlyAccessMutation($input: RequestEarlyAccessInput!) {
    requestEarlyAccess(input: $input) {
      clientMutationId
    }
  }
`;

function commit(environment, input) {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables: { input },
      onCompleted({ requestEarlyAccess }, errors) {
        if (errors) {
          reject(errors[0]);
        } else {
          resolve(requestEarlyAccess);
        }
      },
      onError: reject,
    });
  });
}

export default { commit };
