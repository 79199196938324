/* TODO: Remove this file in favor of EarlyAccess.js */

import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import withWidth from '@material-ui/core/withWidth';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';

import requestEarlyAccessMutation from './mutations/requestEarlyAccess';
import earlyAccessImage from './prepdup.svg';
import LoginLink from '../common/LoginLink';
import Link from '../common/Link';
import { onScroll } from '../utils';
import { useRelay } from '../hooks';
import { ToolbarWithDrawer, Footer } from '../common/PublicLayout';

const PrimaryTypography = styled(Typography)`
  font-family: 'Roboto Slab', serif !important;
  @import url('https://fonts.googleapis.com/css?family=Roboto+Slab:700');
`;

const styles = theme => ({
  gridContainer: {
    maxWidth: '85%',
    minHeight: 'calc( 100vh - 50px )',
    paddingTop: 152,
    margin: '0 auto',

    [theme.breakpoints.down('960')]: {
      flexDirection: 'column-reverse',
      paddingTop: '104px !important',
      justifyContent: 'flex-end',
    },
    [theme.breakpoints.up(1280)]: {
      maxWidth: 1216,
    },
  },
  secondaryText: {
    fontSize: 20,
    paddingTop: 5,
    lineHeight: 1.4,
    color: theme.palette.heading.light,
    fontWeight: 400,
    [theme.breakpoints.down(600)]: {
      fontSize: 16,
      lineHeight: '28px',
    },
  },
  img: {
    maxWidth: '100%',
    [theme.breakpoints.down('960')]: {
      margin: '25px auto',
      display: 'block',
    },
    [theme.breakpoints.down('769')]: {
      margin: '0 auto',
    },
  },
  background: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '100vh',
  },
  requestButton: {
    margin: theme.spacing.unit,
    marginRight: theme.spacing.unit * 2,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    color: theme.palette.background.paper,
    height: 56,
    marginLeft: 0,
    marginTop: theme.spacing.unit * 3,
    [theme.breakpoints.down('480')]: {
      width: '100%',
      marginBottom: 0,
    },
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  adviceButton: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.background.paper,
    color: '#3f49a7',
    height: 56,
    borderWidth: 1.3,
    borderColor: 'rgba(63, 73, 167, 0.62)',
    borderRadius: 0,
    marginLeft: 0,
    marginTop: theme.spacing.unit * 3,
    [theme.breakpoints.down('480')]: {
      width: '100%',
      marginTop: theme.spacing.unit * 2,
    },
  },
  col: {
    [theme.breakpoints.down('960')]: {
      maxWidth: '100%',
      paddingBottom: 0 + '!important',
    },
  },
  fullList: {
    width: 'auto',
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 100,
  },
  textGrey: {
    color: theme.palette.text.secondary,
  },
  primaryText: {
    color: theme.palette.heading.main,
    marginBottom: theme.spacing.unit,
    fontSize: '60px !important',
    lineHeight: '72px !important',
    [theme.breakpoints.down('1280')]: {
      fontSize: '48px !important',
      lineHeight: '60px !important',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '32px !important',
      lineHeight: '38px !important',
    },
  },
  textSpace: {
    [theme.breakpoints.up(960)]: {
      paddingLeft: '46px !important',
    },
    [theme.breakpoints.down(960)]: {
      marginBottom: theme.spacing.unit * 7,
    },
  },
  linkButton: {
    paddingTop: '12px !important',
  },
  dialog: {
    '& >div:last-child': {
      minHeight: 306,
    },
  },
  snackBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  snackbar: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing.unit * 6.25,
  },
  actionButton: {
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    textTransform: 'uppercase',
  },
});

const defaults = {
  openDialog: false,
  error: false,
  email: '',
  text: '',
  openSnackBar: false,
  snackBarMessage: '',
  emailError: false,
  textError: false,
};

class Home extends React.Component {
  state = {
    drawerOpen: false,
    ...defaults,
  };

  toggleDrawer = () => {
    this.setState(x => ({ drawerOpen: !x.drawerOpen }));
  };

  componentDidMount() {
    this.dispose = onScroll(this.handleScroll);
  }

  componentWillUnmount() {
    this.dispose();
  }

  handleScroll = scrollY => {
    if (scrollY > 60 && !this.state.shadow) {
      this.setState({ shadow: true });
    } else if (scrollY < 60 && this.state.shadow) {
      this.setState({ shadow: false });
    }
  };

  showDialog = e => {
    this.setState(x => ({ openDialog: true }));
  };

  inputHandler = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  apply = e => {
    e.preventDefault();
    this.setState({
      error: false,
      emailError: false,
      textError: false,
    });
    requestEarlyAccessMutation
      .commit(this.props.relay.environment, {
        email: this.state.email,
        text: this.state.text,
      })
      .then(result => {
        this.setState({
          openSnackBar: true,
          email: '',
          text: '',
          snackBarMessage:
            'Thanks for applying to test drive.\n We will review your request and get back to you soon.',
        });
        setTimeout(() => {
          this.onCloseSnackBar();
        }, 3000);
      })
      .catch(error => {
        if (error.state) {
          let email = null;
          let text = null;

          for (var key in error.state) {
            if (key === 'email') {
              email = error.state.email[0];
            } else if (key === 'text') {
              text = error.state.text[0];
            }
          }

          this.setState({
            error: true,
            emailError: email,
            textError: text,
          });
        } else {
          this.setState({
            openSnackBar: true,
            email: '',
            text: '',
            snackBarMessage: 'Oops! Something went wrong.',
          });
          setTimeout(() => {
            this.onCloseSnackBar();
          }, 3000);
        }
      });
  };

  hideDialog = e => {
    this.setState(x => ({ ...defaults }));
  };

  onCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') return;
    this.setState({
      openSnackBar: false,
      openDialog: false,
    });
  };

  render() {
    const { classes: s, earlyAccess, width } = this.props;
    const { error: hasError, shadow } = this.state;
    return (
      <div className={s.background}>
        <ToolbarWithDrawer
          toggleDrawer={this.toggleDrawer}
          shadow={shadow}
          earlyAccess={earlyAccess}
          drawerOpen={this.state.drawerOpen}
        />
        <Grid classes={{ container: s.gridContainer }} container spacing={16}>
          <Grid className={s.col} item xs={12} sm={5}>
            <img
              src={earlyAccessImage}
              className={s.img}
              alt={earlyAccessImage}
            />
          </Grid>
          <Grid item className={`${s.col} ${s.textSpace}`} xs={12} sm={7}>
            <Grid item xs={12} sm={12}>
              <PrimaryTypography
                component="h2"
                className={s.primaryText}
                gutterBottom
              >
                Enabling College and Career Success
              </PrimaryTypography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography className={s.secondaryText} gutterBottom>
                Prepdup is an online mentoring platform that connects high
                school students with caring and knowledgeable adults who help
                them prepare for college, career, and life. We&#39;re currently
                providing invite-based access for select partners and users to
                test drive.
              </Typography>
              {!earlyAccess && (
                <Button
                  variant="contained"
                  className={s.requestButton}
                  component={LoginLink}
                >
                  REQUEST FOR ADVICE
                </Button>
              )}
              {!earlyAccess && (
                <Button
                  variant="outlined"
                  className={s.adviceButton}
                  component={Link}
                  href="/apply"
                >
                  GIVE ADVICE
                </Button>
              )}
              {earlyAccess && (
                <Button
                  variant="contained"
                  className={s.requestButton}
                  onClick={this.showDialog}
                >
                  APPLY TO TEST DRIVE
                </Button>
              )}
            </Grid>
          </Grid>
          <Dialog
            fullWidth
            aria-labelledby="post-request"
            open={this.state.openDialog}
            classes={{ root: s.dialog }}
            fullScreen={width === 'xs' ? true : false}
          >
            <DialogTitle id="post-request">Apply to test drive</DialogTitle>
            <DialogContent>
              <form onSubmit={this.apply}>
                <FormControl
                  fullWidth
                  error={hasError}
                  aria-describedby="text-error"
                >
                  <TextField
                    label="Email Address"
                    value={this.state.email}
                    className={s.inputContainer}
                    fullWidth={false}
                    required
                    margin="dense"
                    onChange={this.inputHandler('email')}
                    error={this.state.emailError ? true : false}
                    helperText={hasError ? this.state.emailError : ' '}
                    type="email"
                  />
                  <TextField
                    label="Why are you interested?"
                    value={this.state.text}
                    className={s.inputContainer}
                    fullWidth={false}
                    required
                    margin="dense"
                    onChange={this.inputHandler('text')}
                    error={this.state.textError ? true : false}
                    helperText={hasError ? this.state.textError : ' '}
                  />
                </FormControl>
              </form>
              <Snackbar
                open={this.state.openSnackBar}
                className={s.snackBarContainer}
              >
                <SnackbarContent
                  className={s.snackbar}
                  message={this.state.snackBarMessage}
                />
              </Snackbar>
            </DialogContent>
            <DialogActions>
              <Button className={s.actionButton} onClick={this.hideDialog}>
                Cancel
              </Button>
              <Button
                color="primary"
                variant="raised"
                onClick={this.apply}
                disabled={this.state.email && this.state.text ? false : true}
                className={s.actionButton}
              >
                Apply
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
        <Footer earlyAccess={earlyAccess} />
      </div>
    );
  }
}

const withRelay = () => Component =>
  function WithRelay(props) {
    return <Component {...props} relay={useRelay()} />;
  };

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth(),
  withRelay(),
)(Home);
